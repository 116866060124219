<template>
  <div class="pag">
    <div class="pag1">
      <div class="pag2">
        <wx-open-launch-weapp
          id="launch-btn"
          :username="username"
          env-version="release"
          :path="'pages/home/home?order_sn=' + order_no"
        >
          <script type="text/wxtag-template">
            <button
                style="
                cursor: pointer;
                -webkit-appearance: none;
                font-size: 16px;
                font-weight: 500;
                color: #FFFFFF;
                width: 265px;
               height: 40px;
               background: linear-gradient(180deg, #FFD154 0%, #FF6C00 100%);
               box-shadow: 0px 2px 4px -1px rgba(255, 109, 1, 0.12), 0px 4px 5px 0px rgba(255, 110, 2, 0.08), 0px 1px 10px 0px rgba(255, 110, 2, 0.05);
               border-radius: 8px;
               border: 2px solid;
               border-image: linear-gradient(270deg, rgba(255, 239, 160, 1), rgba(240, 255, 238, 1), rgba(255, 239, 159, 1)) 2 2;
               "
              >
                打开小程序
            </button>
          </script>
        </wx-open-launch-weapp>
      </div>
    </div>
  </div>
</template>
<script>
import { weixinJSconfig } from "@/api/pay";
import Vue from "vue";
import moment from "moment";
Vue.filter("datetime", function (value) {
  return moment(value * 1000).format("YYYY-MM-DD");
});
export default {
  name: "couponslist",
  data() {
    return {
      order_no: "",
      username: "",
    };
  },
  methods: {},
  created() {
    this.order_no = this.$route.query.order_no;
    this.username = this.$route.query.username;
    weixinJSconfig({ url: window.location.href }).then((res) => {
      if (res.resultCode == 1) {
        wx.config(res.resultInfo);
      }
    });
  },
  mounted() {
    if (!localStorage.getItem("num")) {
      localStorage.setItem("num", 1);
      window.location.reload();
    } else {
      let num = localStorage.getItem("num");
      if (num % 2 == 1) {
        num++;
        window.localStorage.setItem("num", num);
      } else {
        num++;
        window.localStorage.setItem("num", num);
        window.location.reload(true);
      }
    }
  },
};
</script>

<style lang="less" scoped>
@w: 0.0181rem;
.pag {
  width: 100vw;
  height: 100vh;
}
.pag1 {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-image: url(./../assets/img/applet.png);
}
.pag2 {
  position: fixed;
  width: 295 * @w;
  height: 100 * @w;
  bottom: 32 * @w;
  left: 60 * @w;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>